<template>
    <div class="mb-3">
        <CRow class="mb-3">
            <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
                <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.inventoryMassiveLoad')}`}} </h6>
            </CCol>
        </CRow>

        <CRow class="mb-3">
            <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-center">
                <vue-dropzone 
                    ref="myVueDropzone" 
                    id="dropzone" 
                    :options="dropzoneOptions" 
                    :useCustomSlot="true"
                    v-on:vdropzone-files-added="sendingEvent"
                    v-on:vdropzone-removed-file="deleteFile"
                    class="col-lg-12"
                    v-on:vdropzone-error="DropzoneError"
                    > 
                    <div>
                    <h3 class="dropzone-custom-title"><CIcon name='cil-cloud-upload'/> ¡{{$t('label.dragAndDropToUploadContent')}}!</h3>
                    <div>...{{$t('label.orClickToSelectFileFromYourComputer')}} </div>
                    </div>
                </vue-dropzone>
            </CCol>
        </CRow>

        <CRow class="mb-3">
            <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-end ">

                <CButton
                    color="excel"
                    class="mr-1"
                    size="sm"
                >
                    <CIcon name="cil-file-excel"/>&nbsp;
                </CButton>

                <CButton
                    color="add" 
                    size="sm"
                >
                    <CIcon name="checkAlt"/>&nbsp;
                </CButton>
                
            </CCol>
        </CRow>
  
        <CRow class="mt-2">
          <CCol sm="12">
              <dataTableExtended
                  class="align-center-row-datatable"
                  :items="computedListContainer"
                  :fields="fields"
                  :items-per-page="5"
                  column-filter
                  pagination
                  :table-filter="tableText.tableFilterText"
                  :items-per-page-select="tableText.itemsPerPageText"
                  :noItemsView="tableText.noItemsViewText"
                  :loading="loading"
                  sorter
              >
              
                  <template #options="{ item }">
                      <td class="text-center">
                          <CButton
                          square
                          size="sm"
                          color="watch"
                          class="d-flex align-items-center"
                          @click="toggleContainer(item)"
                          v-c-tooltip="{
                              content: $t('label.ViewContainer'),
                              placement: 'top-end'
                          }"
                          >
                          <CIcon name='cil-list' />
                          </CButton>
                      
                      </td>
                  </template>
              </dataTableExtended>
          </CCol>
        </CRow>
    </div>
  </template>
  <script>
      import General from '@/_mixins/general';
      import vue2Dropzone from 'vue2-dropzone'
      import 'vue2-dropzone/dist/vue2Dropzone.min.css'
      import { file } from 'jszip';

      let user = JSON.parse(localStorage.getItem('user'));
  
      //data
      function data() {
          return {
                Items: [],
                loading: false,
                ContainerItem:{},
                dataContainer: [
                    { 
                        Nro: '1', 
                        BL: 'SUDU22PHL047781A', 
                        BOOKING : '2PHL047781', 
                        CONSIGNATARIO : 'EXPOMUEBLES H.H., C.A.', 
                        NOTIFICAR : 'EXPOMUEBLES H.H., C.A.', 
                        PROCEDENCIA : 'ECRU, MS, UNITED STATES',
                        AGENTE : 'GLOBAL', 
                        PESO : '25', 
                        VOLUMEN : '23', 
                        CANTIDAD : '20'
                    },
                ],
                VisitBillOfLadingDocId: '',
                DocumentName: '',
                RouteBillLading: '',
                Status: 0,
                BillLadingDocument: [],
                ModalReport: false,
                docFile: null,
                dropzoneOptions: {
                url:`${process.env.VUE_APP_API_BASE}files/`,
                autoProcessQueue: false,
                maxFiles: 1,
                addRemoveLinks: true,
                dictRemoveFile: `${this.$t('label.delete')}`,
                dictCancelUpload: `${this.$t('label.cancelUpload')}`,
                maxfilesexceeded (files) {
                    this.removeAllFiles();
                    this.addFile(files);
                },
                acceptedFiles: '.pdf, .doc, .docx, .xlsx',
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${user.token}`
                }
                },
          };
      }
  
  
      function computedListContainer() {
          return this.dataContainer.filter(item => {
                  return item
          })
      }
  
  
  
      function fields(){ 
          return [
              { key: 'Nro', label: '#', _style: 'width:3%; text-align:center', _classes: 'center-cell text-center', filter: false },
              { key: 'BOOKING', label: this.$t('label.container'),  _classes: 'text-uppercase text-center', _style: 'min-width:180px; text-align:center;', sorter: true, filter: true},
              { key: 'CONSIGNATARIO', label: `${this.$t('label.size')} FT`, _style:'min-width:150px;', _classes: 'text-uppercase text-center', sorter: true, filter: true},
              { key: 'NOTIFICAR', label: this.$t('label.imdg'), _style:'min-width:140px; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true},
              { key: 'PROCEDENCIA', label: this.$t('label.Seals'),  _style:'min-width:150px; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
              { key: 'AGENTE', label: this.$t('label.bl'),  _style:'min-width:170px; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
              { key: 'PESO', label: `${this.$t('label.consignee')}`,  _style:'min-width:100px; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true },
              { key: 'options', label: '', _style: 'min-width:45px;', sorter: false, filter: false },
            ];
      }
      
      function toggleContainer(item) {
       // this.$store.state.yardManagement.yardCollapse = 2;
      }

      function sendingEvent(files){
        if(files[0].name) this.docFile = files[0];
      }

      function deleteFile(file) {
        this.docFile = null;
      }
      function DropzoneError(file) {
        this.$refs.myVueDropzone.removeFile(file);
      }
  
      export default{
          name: 'masive',
          data,
          mixins: [General],
          components: {
            vueDropzone: vue2Dropzone,
          },
          methods: {
            toggleContainer,
            sendingEvent,
            deleteFile,
            DropzoneError,
          },
          computed: {
              fields,
              computedListContainer,
          },
          watch:{
          }
      }
  </script>
  <style lang="scss">
  
      .icon{
          background-color: null; 
          border: 0px;
      }
      .center-cell {
          text-align: center;
      }
      .table-index {
          table {
              td {
              vertical-align: middle !important;
              }
          }
      }
  </style>